@import (reference) '../../../../styles/_library.less';

// TODO: remove .f-header--promobile styles after adding the mobile header on Fnacpro

.f-header {
  background: @color-white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: @z-indexHeader; // over Overlay
}

.f-header--pro {
  background: @color-dark;
  color: @color-white;
}

// Temp style for Fnacpro responsive header
.f-header--promobile {
  transform: none !important;
}

.f-header__subnav {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0 48px 0 72px;
  height: 40px;
}

.f-header__nav {
  display: flex;
  height: 64px;
  padding: 0 48px 0 0;
  width: 100%;

  &.isBordered {
    box-shadow: 0 -1px @color-silver-darker inset;
  }
}

// Temp style for Fnacpro responsive header
.f-header--promobile .f-header__nav {
  padding: 0 20px;
}

// MAIN NAV ---------------------------------------------------------------------------------- /
.f-header__items {
  display: flex;
  margin-left: auto;
}

.f-header__item {
  &,
  .non-legacy & {
    @color: @color-dark;

    align-items: center;
    background: no-repeat left center / auto 24px;
    color: @color-dark;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    margin-left: 40px;
    padding-left: 24px;
    position: relative;
    white-space: nowrap;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;

      &::after {
        content: '';
        border: 1px solid @color-silver-darker;
        position: absolute;
        left: -16px;
        right: -16px;
        top: 4px;
        bottom: 4px;
        border-radius: 8px;
      }
    }

    &:focus-visible {
      outline: 0 !important;

      &::after {
        .focus-visible();
      }
    }

    &--account {
      background-image: colorize(data-uri(icon('person')), @color);
    }

    &--account2 {
      padding-left: 0;
    }

    &--basket {
      background-image: colorize(data-uri(icon('shopping-bag')), @color);
    }
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__item {
  @color: @color-white;

  color: @color-white;

  &--quotation {
    background-image: colorize(data-uri(icon('document')), @color);
  }

  &--account {
    background-image: colorize(data-uri(icon('person')), @color);
  }

  &--basket {
    background-image: colorize(data-uri(icon('shopping-bag')), @color);
  }
}

.f-header__itemText {
  display: none;

  @media (min-width: @screen-tablet-landscape) {
    display: inherit;
    padding-left: 16px;

    &--user {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.f-header__basketCount {
  position: absolute;
  background: @color-black;
  color: @color-white;
  font-weight: bold;
  border-radius: 12px;
  left: 12px;
  top: 12px;
  font-size: 14px;
  padding: 0 6px;
  line-height: 24px;
  min-width: 24px;
  text-align: center;

  &.isEmpty {
    display: none;
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__basketCount {
  background: @color-white;
  color: @color-black;
}

.f-header__initials {
  align-items: center;
  background: @color-gray;
  border-radius: 50%;
  color: @color-white;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  height: 32px;
  justify-content: center;
  width: 32px;

  &--member {
    background: @color-dark;
    color: @color-orange;
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__initials--member {
  background: @color-orange;
  color: @color-white;
}

.f-header__username {
  max-width: 248px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.f-header__member {
  font-weight: normal;

  &--plus {
    color: @color-orange;
  }

  &--oneplus {
    color: @color-dark;
  }

  &--me {
    color: @color-orange;
  }

  &--one {
    color: @color-gray;
  }
}

// SUB NAV ---------------------------------------------------------------------------------- /
.f-header__subitem {
  &,
  .non-legacy & {
    @color: @color-dark;

    background: no-repeat left center / auto 16px;
    color: @color;
    font-size: 14px;
    line-height: 16px;
    margin-left: 56px;
    padding-left: 24px;
    position: relative;
    white-space: nowrap;

    @media (min-width: @screen-sm-min) {
      margin-left: 64px;
    }

    @media (min-width: 1440px) {
      margin-left: 72px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:hover,
    &:focus {
      color: inherit;
    }

    &--help {
      background-image: colorize(data-uri(icon('help+fill')), @color);
      @media (max-width: (@screen-tablet-landscape - 1)) {
        margin-left: 0;
      }
    }

    &--store {
      background-image: colorize(data-uri(icon('pin+fill')), @color);
    }

    &--shipping {
      background-image: colorize(data-uri(icon('express+fill')), @color);
    }

    &--membership {
      padding: 0;

      &-color {
        color: @color-orange;
      }
    }

    &--store2 {
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0;

      &::before {
        content: '';
        border-radius: 50%;
        height: 10px;
        margin-right: 8px;
        width: 10px;
        display: inline-block;
      }

      &.opened::before {
        background: @color-forestGreen;
      }

      &.closed::before {
        background: @color-red;
      }

      &.drive::before {
        background: @color-warning;
      }
    }

    &--switch {
      font-weight: bold;
      padding: 0;

      &:hover,
      &:focus {
        text-decoration: none;

        &::after {
          content: '';
          border: 1px solid @color-silver-darker;
          position: absolute;
          left: -12px;
          right: -12px;
          top: -8px;
          bottom: -8px;
          border-radius: 8px;
        }
      }
    }

    &--slogan {
      font-weight: bold;
      display: none;
      margin: 0 auto 0 0;
      padding: 0;

      @media (min-width: @screen-tablet-landscape) {
        display: inherit;
      }
    }
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__subitem {
  @color: @color-white;

  color: @color;

  &--help {
    background-image: colorize(data-uri(icon('help+fill')), @color);
    margin: 0;
  }

  &--store {
    background-image: colorize(data-uri(icon('pin+fill')), @color);
  }

  &--shipping {
    background-image: colorize(data-uri(icon('express+fill')), @color);
  }
}

// LOGO ---------------------------------------------------------------------------------- /
.f-header__logo {
  flex: 0 0 112px;
  background: colorize(data-uri(icon('icon_i_logo_fnac_02')), #e9aa00) no-repeat 0 50% / 112px auto;
  text-indent: -100%;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 56px 0 72px;

  &--panel {
    margin: 0 0 0 72px;
    max-height: 64px;
  }
}

/* side panel mask on homepage */
// .SideNavPanel--home.isActive f-header__logo--panel::after {
//   content: '';
//   position: absolute;
//   background: @color-white;
//   left: 0;
//   right: 0;
//   top: 40px;
//   bottom: -40px;
//   z-index: -1;
// }

.f-header--pro .f-header__logo {
  background-image: colorize(data-uri(icon('icon_i_logo_fnac-pro')), @color-white);
  background-size: 110px;
}

// Temp style for Fnacpro responsive header
.f-header--promobile .f-header__logo {
  margin: 0;
}

// BURGER -------------------------------------------------------------------------------- /
.f-burger {
  align-items: center;
  background: 0;
  border: 0;
  display: flex;
  height: 64px;
  justify-content: center;
  position: absolute;
  width: 62px;
}

.f-burger__bar {
  &,
  &::before,
  &::after {
    background: @color-black;
    content: '';
    display: inline-block;
    height: 2px;
    left: 0;
    position: relative;
    right: 0;
    width: 16px;
  }

  &::before {
    position: absolute;
    top: -6px;
  }

  &::after {
    position: absolute;
    top: 6px;
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-burger__bar {
  &,
  &::before,
  &::after {
    background: @color-white;
  }
}

.f-burger__text {
  font-size: 10px;
  line-height: 1.2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6px;
}
