// expertItem - smile / nsh / ia
.expertItem {
  position: relative;
  width: auto;
  height: 500px;
  margin-left: 1em;
  margin-right: 1em;
  background: @color-dark;
  color: @color-silver-lighter;
  font-size: unit((13px / 12px), em);
  white-space: normal;
  overflow: hidden;
  display: block;
}

// expertItem-image
.expertItem-image {
  height: 225px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: no-repeat 50% / cover;
  display: flex;
  align-items: center;
  justify-content: center;

  & + .expertItem-content .expertItem-title + .expertItem-description {
    height: 84px;
  }
}

.expertItem-image--video {
  &::before {
    content: '';
    background: url('../../styles-nav/images/expertItem-video.png') no-repeat;
    width: 100px;
    height: 100px;
  }
}

// expertItem-header
.expertItem-header {
  padding: 30px 40px 0 155px;
  height: 130px;
  position: relative;
  overflow: hidden;

  @media (max-width: @screen-md-min) {
    padding: 10px 20px 0 70px;
    height: 60px;
  }

  & + .expertItem-content .expertItem-title + .expertItem-description {
    height: 180px;
  }
}

.expertItem-vignette {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
  top: 40px;
  left: 40px;
  border-radius: 50%;
  border: 2px solid @color-white;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: @screen-md-min) {
    width: 40px;
    height: 40px;
    border: 1px solid @color-white;
    top: 20px;
    left: 20px;
  }
}

.expertItem-author {
  color: @color-white;
  white-space: normal;
  padding-top: 30px;

  @media (max-width: @screen-md-min) {
    padding-top: 0;
  }
}

.expertItem-authorLink {
  color: @color-white;

  &:hover,
  &:focus {
    color: @color-white;
  }
}

.expertItem-authorName {
  color: @color-orange;
}

// expertItem-content
.expertItem-content {
  padding: 40px;

  @media (max-width: @screen-md-min) {
    padding: 20px;
  }
}

.expertItem-categorie {
  color: @color-orange;
  margin: 0;
}

.expertItem-title {
  .typoFnacLight();
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 25px;
  line-height: 29px;
  color: @color-white;
  min-height: 60px;

  & + .expertItem-description,
  & + .expertItem-list + .expertItem-description {
    height: 310px;
    overflow: hidden;
    font-size: 15px;
    margin-top: 10px;
    line-height: 1.4;
    display: inline-block;
  }

  & + .expertItem-list + .expertItem-description {
    height: 80px;

    @media (max-width: @screen-md-min) {
      height: 180px;
    }
  }
}

.expertItem-titleLink {
  color: inherit;

  &:hover,
  &:focus {
    color: inherit;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }

  &::before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    right: 0;
    height: 500px;
    position: absolute;
  }
}

.expertItem-titleLink {
  color: inherit;

  &:hover,
  &:focus {
    color: inherit;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }

  &::before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    right: 0;
    height: 500px;
    position: absolute;
  }
}

// expertItem-description
.expertItem-description {
  color: @color-silver-darker;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
}

// expertItem-list
.expertItem-list {
  list-style: none;
  margin: 0 0 10px 0;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
}

.expertItem-listItem {
  width: auto;
}

.expertItem-listLink {
  display: block;
  overflow: hidden;
  color: @color-gray;
  text-decoration: underline;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  position: relative;
  z-index: 10;

  .f-icon {
    color: @color-orange;
    font-size: 8px;
    margin-right: 5px;
  }
}

// expertItem-more
.expertItem-more {
  color: @color-orange;
  position: absolute;
  right: 40px;
  bottom: 20px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.expertItem-moreBg {
  content: '';
  background: linear-gradient(0deg, @color-dark, @color-dark, transparent);
  width: 100%;
  height: 90px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
