// ---------
// FORMS

// HOW TO USE IT
// markup HTML
// ex :
//
// <div class="f-select">
//    <select>
//       <option>select option 1</option>
//       <option>example : option 2</option>
//    </select>
// </div>
// <div class="f-select--arrow"></div>

.f-select {
  position: relative;
  display: inline-block;
  // margin-bottom: 15px;
  width: 100%;
}

.f-select select {
  appearance: none;
  background: transparent;
  cursor: pointer;
  border: 1px solid @color-silver-darker;
  border-radius: 3px;
  color: #7b7b7b;
  display: inline-block;
  padding: 7px 23px 7px 15px;
  outline: 0;
  width: 100%;
}

.f-select select::-ms-expand {
  display: none;
}

.f-select select:hover,
.f-select select:focus {
  background: transparent;
  color: @color-black;
}

.f-select select:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.f-select--arrow {
  height: 20px;
  position: absolute;
  pointer-events: none;
  right: 5px;
  top: 6px;
  width: 20px;
  @icon-url: icon('chevron-down');
  background: colorize(data-uri(icon('chevron-down')), @color-dark) right center / 0 no-repeat;
  background-size: 20px;
}

.f-select select:hover ~ .f-select--arrow,
.f-select select:focus ~ .f-select--arrow {
  border-top-color: @color-black;
}

.f-select select:disabled ~ .f-select--arrow {
  border-top-color: #ccc;
}

// HOW TO USE IT
// markup HTML
// ex :
//
//    <select class="ff-select">
//       <option>select option 1</option>
//       <option>example : option 2</option>
//    </select>
.ff-select {
  padding: 10px 50px 0 0;
  font-size: 15px;
  line-height: 21px;
  color: @color-dark;
  background-color: @color-white;
  appearance: none;
  border: none;
  border-bottom: 1px solid @color-gray-darker;
  background-image: colorize(data-uri(icon('chevron-down')), @color-dark);
  background-repeat: no-repeat;
  background-position:
    right 10px top 15px,
    0 0;
  background-size:
    20px auto,
    100%;

  &__option {
    font-size: 13px;
    line-height: 16px;
    padding: 5px;
    color: @color-dark;
  }

  &::-ms-expand {
    display: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &--full {
    width: 100%;
  }

  &--border {
    padding: 10px 40px 10px 15px;
    border: 1px solid @color-silver-darker;
    background-position:
      right 15px top 50%,
      0 0;
    color: @color-gray;

    &:focus {
      border-color: @color-silver-darker;
    }
  }
}

input {
  outline: none;

  &[type='text']::-ms-clear {
    display: none;
  }

  &[type='password']::-ms-reveal {
    display: none;
  }

  &[type='search'] {
    appearance: none;
  }

  &.error {
    border: 1px solid @color-red !important;
  }
}

.input-validation-error {
  border-bottom: 1px solid @color-red;
  display: block;
}

.field-validation-error {
  padding: 10px;
  display: block;
  text-align: left;
  background: @color-orange;
  color: @color-white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

input,
textarea {
  font-style: normal;
  appearance: none;

  &:focus {
    border-color: @color-dark;
    outline: none;
  }
}

input::placeholder {
  font-style: normal;
}

input.placeholder,
textarea.placeholder {
  font-style: normal;
}

// Checkbox
// Radio, Checkbox
//
// Specific Radio checkbox native orrides
//
input[type='checkbox'],
input[type='radio'] {
  display: none;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  margin-left: 25px;
  position: relative;
  display: inline-block;
  line-height: 18px;
}

input[type='checkbox'] + label::before,
input[type='radio'] + label::before {
  content: ' ';
  text-align: center;
  display: inline-block;
  margin-left: -25px;
  position: absolute;
  top: 0;
  margin-top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid @color-gray;
  background: @color-white;
}

input[type='checkbox'] + label::before {
  font-size: 25px;
  line-height: 8px;

  @media screen and (min-resolution: 0dppx) {
    width: 16px;
    height: 16px;
    font-size: 1.65em;
  }
}

input[type='radio'] + label::before {
  font-size: 1.8em;
  line-height: 10px;
  border-radius: 50%;

  @media screen and (min-resolution: 0dppx) {
    line-height: 10px;
  }
}

input[type='radio']:checked + label::after,
input[type='radio']:not(:checked) + label:hover::after {
  content: '';
  background: @color-orange;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 5px;
  left: -22px;
  border-radius: 50%;
}

input[type='checkbox']:checked + label::after,
input[type='checkbox']:not(:checked) + label:hover::after {
  width: 10px;
  content: '';
  height: 10px;
  top: 5px;
  left: 3px;
  position: absolute;
  background: @color-orange;
}

input[type='checkbox']:not(:checked) + label:hover::after,
input[type='radio']:not(:checked) + label:hover::after {
  background: @color-silver-darker;
}

input[type='radio']:not(:checked) + label:hover::before,
input[type='checkbox']:not(:checked) + label:hover::before {
  color: @color-silver-darker;
}

input[type='radio']:disabled:not(:checked) + label::before,
input[type='checkbox']:disabled:not(:checked) + label::before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

input.hide[type='radio'] + label {
  margin-left: 0;
}

input.hide[type='radio'] + label::before {
  display: none;
}

// SIZE CHECKBOX
input[type='checkbox'].f-Checkbox--xsmall {
  &:checked,
  &:not(:checked) {
    & ~ label {
      padding-left: 18px;

      &::before {
        height: 12px;
        width: 12px;
        top: 4px;
      }

      &::after {
        border-width: 2px;
        height: 10px;
        width: 10px;
        top: 5px;
      }
    }
  }
}

input[type='checkbox'].f-Checkbox--small {
  &:checked,
  &:not(:checked) {
    & ~ label {
      padding-left: 24px;

      &::before {
        height: 14px;
        width: 14px;
        top: 2px;
      }

      &::after {
        height: 12px;
        width: 12px;
        top: 3px;
      }
    }
  }
}
