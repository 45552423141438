.Category {
  display: inline-block;
  margin-bottom: -5px;
  &.strate-NoTitle {
    border-top: 0;
  }
}

.Category-item {
  border: 1px solid @color-silver-darker;
  border-left-width: 0;
  float: left;
  width: 25%;
  margin-top: -1px;
  position: relative;
  @media (max-width: @screen-md-min) {
    width: 50%;
  }
}

.Category-itemTitle {
  color: @color-dark;
  font-size: 18px;
  margin-bottom: 10px;
  display: inline-block;
  height: 25px;
  overflow: hidden;
  bottom: 0;
  left: 10px;
  right: 10px;
  &:hover {
    text-decoration: none;
  }
}

.Category-itemContent {
  border: 5px solid @color-white;
  padding: 20px;
  position: relative;
  height: 340px;
  transition: all 0.2s linear;

  &:hover {
    border-color: @color-silver-darker;
  }
}

.Category-itemContent--noLink {
  .Category-imageWrapper {
    margin-bottom: 50px;
  }

  .Category-itemTitle {
    margin-bottom: 10px;
    height: 48px;
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    position: absolute;
    &:hover {
      text-decoration: none;
      color: @color-black;
    }
  }
}

.Category-imageWrapper {
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
  overflow: hidden;
}

.Category-image {
  height: 140px;
  max-width: 100%;
  display: inline-block;
}

.Category-subCategoryWrapper {
  max-height: 80px;
  overflow: hidden;
}

.Category-subCategory {
  color: @color-gray-darker;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  line-height: 1.4;
  position: relative;
}
