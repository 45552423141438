@path: if(@ismobile, '../../styles/_library-mobile.less', '../../styles/_library.less');
@import (reference) '@{path}';

@compare-page-margin: 10px;
@compare-page-padding: 30px;
@compare-cell-font-size: 13px;
@compare-cell-line-height: 1.2;
@compare-cell-horizontal-padding: 12px;

.f-compare-page {
  padding: 0 @compare-page-margin @compare-page-padding;
  background: @color-white;

  @media (min-width: @screen-md-min) {
    padding: 0 @compare-page-padding @compare-page-padding;
  }

  &--empty {
    height: auto;
    padding: 75px 25px;
    background: @color-silver-lighter;
    font-size: 15px;
    line-height: 1.5;
    text-align: center;
  }

  &__icon {
    width: 80px;
    height: 80px;
    color: @color-silver-darker;
  }

  &__text-wrapper {
    margin: 15px 0 28px;
  }

  &__text {
    margin: 0;
    color: @color-gray;

    &--bold {
      font-weight: bold;
    }
  }

  &__link {
    color: @color-orange;

    &:hover,
    &:focus {
      color: @color-orange;
    }
  }

  &__table-wrapper {
    margin: 0 -@compare-page-margin;
    overflow-x: auto;

    @media (min-width: @screen-md-min) {
      margin: 0;
      overflow-x: visible;
    }
  }
  ~ .f-compare-banner {
    display: none;
  }
}
& when (@ismobile) {
  .Compare .f-compare-banner {
    display: none;
  }
}

.f-compare-table {
  @border: 1px solid @color-silver-darker;
  @first-column-width: 20%;
  @other-column-width: 16%;

  position: relative;
  table-layout: fixed;
  min-width: 100%;

  @media (min-width: @screen-md-min) {
    width: 100%;
  }

  &__head-row {
    transition: transform 0.3s ease;

    .f-compare-table--sticky-top & {
      position: fixed;
      top: @header-nav-mobile;
      display: table;
      table-layout: fixed;
      min-width: 0;
      z-index: 1;

      @media (min-width: 600px) {
        top: @header-nav;

        border-bottom: 1px solid @color-silver-darker;
        margin-left: -1px; // hide the table border on scroll up
      }

      @media (min-width: @screen-md-min) {
        width: calc(100% - @compare-page-padding * 2);
      }
    }

    .f-compare-table--sticky-top.f-compare-table--scrolledUp & {
      transform: translateY(44px);

      @media (min-width: 600px) {
        transform: translateY(@header-nav);
      }
    }

    .f-compare-table--sticky-bottom & {
      position: absolute;
      top: auto;
      bottom: 0;
      width: 100%;
    }
  }

  &__head-placeholder {
    display: none;

    .f-compare-table--sticky-top & {
      display: table-row;
      visibility: hidden;
    }
  }

  &__body {
    border: @border;
    color: @color-gray-lighter;
  }

  &__placeholder {
    display: none;
    background: @color-white;

    @media (min-width: @screen-md-min) {
      display: table-cell;
      width: @first-column-width;
    }
  }

  &__article {
    width: @other-column-width;
    background: @color-white;
    border: @border;
    vertical-align: top;

    @media (min-width: @screen-md-min) {
      border: 0;
    }

    // Override product thumbnail styles
    .miniFA__bottom {
      position: relative;
    }
    .miniFA__priceList {
      margin-bottom: 14px;
      + .f-info {
        position: absolute;
        top: 35px;
      }
    }
  }

  &__article-wrapper {
    @media (min-width: @screen-md-min) {
      margin: 10px 5px;
      border-radius: 5px;
      box-shadow: 0 0 3px 1px @color-silver-light;
    }
  }

  &__row:nth-child(even) {
    background: @color-silver-light;
  }

  &__cell {
    padding: 10px @compare-cell-horizontal-padding;
    font-size: @compare-cell-font-size;
    line-height: @compare-cell-line-height;
    vertical-align: top;
  }

  &__key {
    display: none;
    width: @first-column-width;
    font-weight: bold;
    text-align: left;

    @media (min-width: @screen-md-min) {
      display: table-cell;

      & &Icon {
        float: right;
      }
    }
  }

  &__data {
    width: @other-column-width;
    border: @border;
    text-align: center;
    vertical-align: top;
    padding: 10px @compare-cell-horizontal-padding;
    font-size: 12px;

    @media (min-width: @screen-md-min) {
      border-bottom: 0;
      border-top: 0;
    }
  }

  &__labo .f-laboProgress__label {
    font-size: inherit;
    display: block;
  }

  &__label {
    display: block;
    font-weight: bold;
    hyphens: auto;
    overflow-wrap: break-word;

    @media (min-width: @screen-md-min) {
      display: none;
    }
  }

  &__value {
    display: block;
    overflow-wrap: break-all;
    overflow-wrap: anywhere;
  }

  &__rate {
    color: @color-LabBlue;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;

    &Icon {
      margin: 0 6px 0 0;
    }
  }

  // Override product thumbnail styles
  .miniFA {
    margin: auto;
    padding: 10px;

    @media (min-width: @screen-md-min) {
      width: 100%;
    }

    &__rating {
      opacity: 1;
      transition:
        height 0.25s,
        opacity 0.25s;
    }

    &__top {
      height: auto;
    }

    &__image {
      transition: height 0.25s;
    }

    &__title {
      transition: height 0.25s;
    }

    &__button {
      min-width: 100%;
      margin-top: 8px;
    }

    &-placeholder {
      margin: 0;
    }
  }

  &--sticky-top .miniFA {
    &__rating {
      height: 0;
      padding: 0;
      margin: 0;
      opacity: 0;
    }

    &__top {
      margin-bottom: 0;
    }

    &__image {
      height: 60px;
    }

    &__title {
      height: 15px;
    }
  }
}
