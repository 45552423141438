@import (reference) '../../styles/_library.less';

.f-kamino-title {
  p {
    color: @color-dark;
    margin-bottom: 0;
    display: block; // fallback
    display: -webkit-box;
    line-height: 1em;
    max-height: 2em; // fallback
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
}
