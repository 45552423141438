.categoryMosaic {
  margin-bottom: 8px;
  border-top: 0;
  &Carousel {
    margin-bottom: 8px;
    border-top: 0;
  }
  .f-nCarousel__scroller {
    align-items: center;
  }
  .f-nCarousel__item {
    // because pub
    height: 285px;
  }
  .f-nCarousel__itemInner {
    min-height: 260px;
  }
}

.categoryMosaic-wrapper {
  border-top: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.categoryMosaic-thumb {
  box-shadow:
    0 0 12px rgb(0 0 0 / 5%),
    0 5px 8px 0 rgb(0 0 0 / 5%),
    0 2px 4px 0 rgb(0 0 0 / 5%);
  float: left;
  text-align: center;
  border: none;
  border-radius: 5px;
  margin: 8px;
  width: 46.5%;
  @media (min-width: @screen-md-min) {
    width: 22.5%;
  }
  @media (min-width: 1700px) {
    width: 15.5%;
  }
  .categoryMosaicCarousel & {
    width: 300px;
    float: unset;
  }
}

.categoryMosaic-title {
  display: block;
  text-align: left;
  font-size: 1.5em;
  font-weight: normal;
  padding: 16px;
  border-top: none;
  .text-overflow();
  color: @color-dark;
  transition: 0.2s linear;

  @media (max-width: 992px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.categoryMosaic-link {
  text-decoration: none;

  &:hover {
    text-decoration: none;

    .categoryMosaic--title {
      background: @color-silver-light;
    }

    .categoryMosaic-image {
      transform: scale(1.05);
    }
  }
}

.categoryMosaic-imageWrap {
  border-radius: 5px 5px 0 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.categoryMosaic-image {
  max-width: 100%;
  width: 100%;
  min-width: 102%;
  height: auto;
  transform: scale(1);
  transition: 0.2s linear;
  backface-visibility: hidden;
}

.row.categoryMosaic-wrapper .categoryMosaic-advertising {
  width: 46.5%;
  position: absolute;
  right: 8px;
  top: 0;
  text-align: center;
  border-right: none;
  height: 0;
  overflow: hidden;
  @media (min-width: 992px) {
    width: 22.5%;
  }

  @media (min-width: 1700px) {
    width: 15.5%;
  }

  & > div:first-of-type {
    height: 0;
    padding-top: 0;
    position: relative;
    margin: 0;
    transform-origin: top;

    & > iframe {
      left: 50% !important;
      position: absolute !important;
      top: 0 !important;
      transform-origin: top !important;
      transform: translate(-50%);
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
  .categoryMosaicCarousel & {
    width: 300px;
    flex-wrap: wrap;
    margin: 8px;
    position: unset;
    right: unset;
  }
}

.categoryMosaic-advertisingPixel {
  display: none;
}

.categoryMosaic-advertisingText {
  border-bottom: 1px solid @color-silver-darker;
  color: #757575;
  font-size: 0.7em;
  font-weight: normal;
  height: 17px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
}
