@import (reference) '../../../styles/_library.less';

.f-kamino-squad {
  position: relative;
  display: flex;
  background-color: @color-silver-darker;
  margin: 20px;
  flex-flow: column;
  box-shadow:
    0 0 12px rgb(0 0 0 / 5%),
    0 5px 8px 0 rgb(0 0 0 / 5%),
    0 2px 4px 0 rgb(0 0 0 / 5%);
  border-radius: @base-radius @base-radius 0 0;
  .categoryMosaicCarousel & {
    background-color: @color-white;
    margin: 8px;
    height: 260px;
    width: 300px;
  }

  .f-kamino-advertisement {
    width: 100%;
    height: auto;
    border-radius: @base-radius @base-radius 0 0;

    &-wrapper {
      display: flex;
    }
  }

  .f-kamino-video {
    border-radius: @base-radius @base-radius 0 0;
  }

  .f-kamino {
    &-visual {
      width: 60px;
      align-self: center;
      margin-right: 8px;
      height: auto;
      &-link {
        display: contents;
      }
    }
    &-sponsored {
      display: flex;
      justify-content: flex-end;
      color: @color-gray;
      align-items: center;
      position: absolute;
      bottom: -25px;
      border: 0;
      font-size: 14px;
      right: 0;
      background: transparent;
      .categoryMosaicCarousel & {
        font-size: 11px;
      }
      &--icon {
        font-size: 18px;
        margin-left: 5px;
        .categoryMosaicCarousel & {
          font-size: 15px;
        }
      }
    }
    &-info {
      background-color: @color-white;
      padding: 10px;
      display: flex;
      border-radius: 0 0 @base-radius @base-radius;
      flex-grow: 1;
      .categoryMosaicCarousel & {
        padding: 8px;
      }

      &--link {
        display: contents;
      }
    }
    &-buybox {
      display: flex;
      width: 70%;
      flex-grow: 1;
      column-gap: 10px;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      position: relative;

      .thumbnail-greenLabel {
        position: absolute;
        right: 48px;
      }

      &-price {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;

        &__price-wrapper {
          display: flex;
          flex-direction: column;
        }
        &__bottom-wrapper {
          position: relative;
        }
        .thumbnail-greenLabel {
          right: 48px;
          top: 0;
          left: unset;
          height: auto;
        }
      }

      &-bottom {
        display: flex;
        width: 100%;
        position: relative;
        justify-content: space-between;
        align-items: baseline;
        white-space: nowrap;
      }
      .price {
        font-size: 18px;
        color: @color-error;
        & when (@ismobile) {
          font-size: 23px;
        }
      }
      .oldPrice {
        font-size: 13px;
        color: @color-gray;
        & when (@ismobile) {
          font-size: 16px;
        }
      }
      &-promo {
        display: none;
      }
      &-promo {
        color: @color-error;
      }
    }
    &-title {
      width: 100%;
      text-align: left;
      white-space: wrap;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  button {
    padding: 8px 10px;
    font-size: 1.6em;
    color: @color-black;
    & when (@ismobile) {
      font-size: 1.4em;
    }
  }
}

.categoryMosaic-wrapper {
  .f-kamino-squad {
    margin: 0;
  }
}

.row.categoryMosaic-wrapper {
  .categoryMosaic-advertising {
    position: relative;
    width: 46.5%;
    order: 1;

    @media (min-width: @screen-md-min) {
      width: 22.5%;
      order: 4;
    }

    @media (min-width: 1700px) {
      width: 15.5%;
      order: 6;
    }
    .categoryMosaicCarousel & {
      width: unset;
    }
  }

  .f-kamino-squad,
  .kmino-squad-home {
    // overflow: hidden;
    box-shadow:
      0 0 12px rgb(0 0 0 / 5%),
      0 5px 8px 0 rgb(0 0 0 / 5%),
      0 2px 4px 0 rgb(0 0 0 / 5%);
    float: left;
    text-align: center;
    border: none;
    border-radius: 5px;
    margin: 8px;
    width: 46.5%;
    order: 1;
    position: relative;

    @media (min-width: @screen-md-min) {
      width: 22.5%;
      order: 4;
    }

    @media (min-width: 1700px) {
      width: 15.5%;
      order: 6;
    }

    .f-kamino {
      &-visual {
        width: 20%;
        height: auto;
        margin-right: 8px;
      }
      &-title {
        text-align: left;
        white-space: wrap;
      }
      &-sponsored {
        border: 0;
        font-size: 12px;
        bottom: -30px;
      }
      &-buybox {
        &-promo {
          display: none;
        }
      }
    }
    .categoryMosaicCarousel & {
      width: 300px;
    }
  }
}

.js-lazy-html-cacheable {
  display: contents;
}
