.stratePlayer {
  border-top: 0;

  .f-nCarousel:not(.hasJs) {
    display: block;
  }

  .f-nCarousel__scroller {
    align-items: center;
    transform: translateX(0); // To avoid shift when running js
  }

  .f-nCarousel__item {
    min-width: 100%; // To avoid shift when running js
  }

  .f-nCarousel--arrows {
    padding: 0;
  }

  .f-nCarousel--bullets {
    display: none;

    @media (min-width: @screen-lg-min) {
      display: block;
    }
  }

  .f-nCarousel {
    .nCarouselRoundedArrows();
    .nCarouselPositionArrows(5px);
  }
}

.Player {
  border-left: 0;
  overflow: hidden;

  &--light {
    color: @color-white;

    a {
      color: @color-white;
    }
  }

  &--dark {
    color: @color-black;

    a {
      color: @color-dark;
    }
  }
}

.Player-tableWrapper {
  display: table;
  max-width: 1000px;
  width: 100%;
  height: 100%;
  margin: auto;
}

.Player-tableRow {
  display: table-row;

  & > * {
    display: table-cell;
  }
}

.Player-date {
  display: inline-block;
  font-size: unit((15px / 12px), em);
  color: @color-dark;
  margin-top: 10px;
  width: 100%;

  .f-icon {
    margin-right: 10px;
    font-size: 16px;
  }
}

.Player-content {
  height: inherit;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  @media (max-width: 1200px) {
    height: 100%;
  }
}

.Player-content--left {
  text-align: left;

  .Player-ctaThis {
    margin-right: 10px;
  }
}

.Player-content--center {
  text-align: center;

  .Player-ctaThis {
    margin-right: 2px;
    margin-left: 2px;
  }
}

.Player-content--right {
  text-align: right;

  .Player-ctaThis {
    margin-left: 10px;
  }
}

.Player-title,
.Player-subTitle {
  height: 32px;
  font-size: 22px;
  line-height: 26px;
  margin: 0;
  font-weight: 300;
  width: 100%;
  margin-top: 5px;
}

.Player-title {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 36px;
  margin-top: 0;
}

.Player-countdown {
  display: block;
}

.Player-countBox {
  font-size: 26px;
  color: @color-white;
  display: inline-block;
  padding: 0 15px;
  font-weight: 300;
  width: 80px;
  height: 60px;
  background: url('../../../../Images/bgcountdown.png') no-repeat left top;
  margin-right: 0;
  margin-top: 10px;
  text-align: center;
  line-height: 50px;
}

.Player-image {
  margin: 0 auto;
  display: block;

  .f-nCarousel & {
    max-width: 1000px;
    position: static;
    transform: none;
  }
}

// Size players
@media (min-width: @screen-lg-min) {
  .Player {
    &,
    .Player-blocWrapper {
      height: 270px;
    }
  }

  .Player--extraSmall {
    &,
    .Player-blocWrapper {
      height: 45px;
    }
  }

  .Player--small {
    &,
    .Player-blocWrapper {
      height: 120px;
    }
  }

  .Player--medium {
    &,
    .Player-blocWrapper {
      height: 190px;
    }
  }
}

.Player-blocWrapper {
  width: 100%;
  position: relative;
}

.Player-ctaThis,
.Player-ctaAll {
  height: 40px;
  border-radius: @base-radius;
  font-size: 14px;
  line-height: 40px;
  color: @color-dark;
  border: 1px solid @color-dark;
  display: inline-block;
  padding: 0 15px;
  min-width: 170px;
  margin-top: 10px;
  text-align: center;
  position: relative;

  // Player fully clickable
  &:first-child {
    position: static;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &:hover {
    text-decoration: none;
    background: @color-dark;
    color: @color-white;
  }

  .Player--light & {
    color: @color-white;
    border-color: @color-white;

    &:hover {
      text-decoration: none;
      background: @color-white;
      color: @color-dark;
    }
  }

  .Player--dark & {
    color: @color-dark;
    border-color: @color-dark;

    &:hover {
      text-decoration: none;
      background: @color-dark;
      color: @color-white;
    }
  }

  &--bg {
    background: @color-dark;
    color: @color-white;

    &:hover {
      opacity: 0.8;
    }

    .Player--light & {
      background: @color-white;
      color: @color-dark;
    }

    .Player--dark & {
      background: @color-dark;
      color: @color-white;
    }
  }
}

.Player-contentInner {
  padding: 40px;
}

.Player-buttonWrapper {
  width: 100%;
  padding: 0 40px 40px;
  vertical-align: bottom;

  @media (max-width: @screen-md-max) {
    bottom: 20px;
    left: 20px;
    padding: 0;
    min-height: 40px;
  }
}

@media (max-width: @screen-md-max) {
  .Player {
    height: auto;
  }

  .Player-image,
  .f-nCarousel .Player-image {
    height: auto;
    max-width: 100%;
  }

  .Player-contentInner {
    padding: 0;
    display: none;
  }

  .Player-content,
  .Player-buttonWrapper {
    position: static;
  }

  .Player-buttonWrapper {
    bottom: 0;
    padding: 0;
    text-align: center;
  }

  .Player-ctaThis {
    margin: 10px 0;
  }

  .Player-blocWrapper {
    &::before {
      content: '';
      display: block;
    }

    .Player--extraSmall & {
      &::before {
        padding-top: 0;
      }
    }

    .Player--small & {
      &::before {
        padding-top: 0;
      }
    }

    .Player--medium & {
      &::before {
        padding-top: 0;
      }
    }
  }
}
