@import (reference) '../../../../styles/_library.less';

// TODO : refactor this !!!!!
.StorePicker {
  width: 700px;
  max-width: 100%;
  background: @color-white;
  margin: 0 auto;
  position: relative;

  .mfp-close-btn-in & .mfp-close {
    color: @color-white;
  }

  .filterBox {
    display: inline-block;
    padding: 0 0 0 50px;

    span {
      display: flex;
      margin: 4px 0;
    }
  }

  .store-adr {
    width: 320px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 0 40px 0 67px;
  }

  .store-adr-icon {
    position: absolute;
    color: @color-gray-darker;
    left: 48px;
    top: 0;
    height: 17px;
    width: 17px;
  }

  .store-hour {
    width: 320px;
    position: relative;
    display: inline-block;
    vertical-align: top;

    span {
      width: 60px;
      display: inline-block;
    }

    p,
    b {
      padding-left: 25px;
    }

    p:last-child {
      margin: 0;
    }
  }

  .store-hour-icon {
    position: absolute;
    color: @color-gray-darker;
    left: 5px;
    top: 0;
    height: 17px;
    width: 17px;
  }

  .storeInfoContent {
    padding-bottom: 25px;
    padding-top: 25px;
  }

  .StorePickerResults {
    height: 350px;
    overflow: auto;
    position: relative; // need to the overlay/loader

    &__list {
      padding: 0;
    }

    &__text {
      padding: 30px 45px;

      &Link {
        color: @color-orange;
      }
    }

    .black_bold b {
      color: @color-dark;
    }

    .liStore {
      list-style: none;
      border-bottom: 1px solid @color-silver-darker;
      overflow: hidden;

      &:nth-child(odd) {
        background: @color-grey-light;
      }

      .divStoreInfo {
        cursor: pointer;
        min-height: 40px;
      }

      .divStoreDetail {
        background: @color-silver-lighter;
        padding: 30px 0;
        border-top: 1px solid @color-silver-darker;
        position: relative;
      }

      .storeInfos {
        padding: 10px 40px 0 50px;

        & > span {
          padding-left: 17px;
          display: block;
        }
      }

      .liCol_1 {
        display: inline-block;
        vertical-align: top;
        width: 330px;
        padding-left: 70px;
        position: relative;
        color: @color-gray;

        html[lang*='-ES'] & {
          width: 300px;
        }

        .opened {
          color: @color-forestGreen;
          .typoFnacRegular();
        }

        .closed {
          .typoFnacRegular();
          position: static;
          color: @color-red;
        }

        .favPict {
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          width: 70px;

          & > .f-icon {
            color: #e1a925; // Fnac Logo
            height: 31px;
            width: 45px;
          }
        }

        .icon_grey {
          color: @color-silver-darker;
        }
      }

      .storeName {
        .typoFnacBold();
        color: @color-dark;
        cursor: pointer;

        &::after {
          content: ' ';
          margin-right: 0.4em;
        }
      }

      .drive {
        align-items: center;
        color: @color-dark;
        display: flex;
        font-size: 13px;
        margin-top: 5px;

        &Icon {
          height: 36px;
          margin-right: 8px;
          min-width: 40px;
          width: 40px;
        }
      }

      .status-location {
        margin-left: 16px;
      }

      .liCol_2 {
        display: inline-block;
        vertical-align: top;
        width: 140px;
        line-height: 16px;
        color: @color-gray;

        @media (max-width: 767px) {
          margin-left: 50px;
          margin-top: 10px;
        }

        .status-color {
          width: 100%;
          display: inline-block;
        }

        .status-color--solo {
          line-height: 40px;
        }

        .status-color__icon {
          font-size: 16px;
        }
      }

      .liCol_3 {
        display: inline-block;
        vertical-align: top;
        width: 185px;

        html[lang*='-ES'] & {
          width: 215px;
        }

        @media (max-width: 767px) {
          margin-left: 50px;
          margin-top: 10px;
        }
      }

      .ColorStatus_2,
      .ColorStatus_1 .status-color {
        //en rayon
        color: @color-forestGreen;
      }

      .ColorStatus_0 .status-color {
        color: @color-red;
      }
    }
  }

  .StoreInfo {
    background: @color-dark;
    color: @color-white;
    font-size: unit((13px / 12px), em);
    padding: 0 50px 25px;
    margin-bottom: 20px;

    h3 {
      margin-top: 0;
      font-size: 1.2em;
    }
  }

  .StoreInfo-hours {
    display: inline-block;
    width: 1em;
    margin: -1px 0 0 -2px;
    padding-left: 0;
    position: absolute;
    .typoFnacRegular();
    font-size: 17px;
    color: @color-forestGreen;

    &--closed {
      color: @color-red;
    }
  }
}

.StorePicker__title {
  background: @color-dark;
  color: @color-white;
  .typoFnacLight();
  margin: 0;
  font-size: 27px;
  padding: 25px 50px;
}

.StorePicker__header {
  padding-bottom: 20px;
  border-bottom: 1px solid @color-silver-darker;
}

.StoreSearch {
  display: inline-block;
  vertical-align: top;
  margin-left: 45px;

  &.error {
    border: 1px solid @color-red;
  }

  &__input {
    border: 0;
    padding: 13px 10px;
    line-height: 1;
    width: 240px;
    outline: none;
    background: @color-silver-lighter;
    box-shadow: inset 0 0 5px rgba(@color-black, 0.09);
    transition: box-shadow 0.5s;
    color: @color-gray-darker;
    font-style: normal;

    &::-ms-clear {
      display: none;
    }

    &:hover,
    &:focus {
      color: @color-dark;
      background: @color-silver-lighter;
      box-shadow: inset 0 0 5px rgba(@color-black, 0.28);
      outline: 0;
    }
  }

  input[type='checkbox'] {
    float: left;
    margin-top: 2px;
    margin-right: 4px;
  }

  .btnSearchStore {
    // line-height: 0;
    border-radius: 0 2px 2px 0;
    font-size: 16px;
    // padding: 6px;
    height: 40px;
    // margin: 0;
    float: right;
    width: 40px;
    // background: @color-orange;
    // color: @color-white;

    .focus-visible-dark();
  }
}

.StoreOpened,
.StoreOpenedDrive,
.StoreClosed,
.StoreClosedUntil,
.StoreNotYetOpened {
  font-weight: bold;
  padding-left: 15px;
  position: relative;

  &::before {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    display: block;
    content: '';
    top: 2px;
    left: 0;
  }
}

.StoreOpened {
  color: @color-forestGreen;

  &::before {
    background: @color-forestGreen;
  }
}

.StoreOpenedDrive {
  color: @color-warning;

  &::before {
    background: @color-warning;
  }
}

.StoreClosed,
.StoreClosedUntil {
  color: @color-red;

  &::before {
    background: @color-red;
  }
}

.StoreNotYetOpened {
  color: @color-orange;

  &::before {
    background: @color-orange;
  }
}

.StoreExist {
  padding: 0 0 0 10px;
  margin: 20px 45px 0;
  border-left: 3px solid @color-orange;
}
