@checkboxSize: 24px;

.f-checkbox {
  position: relative;
  display: block;
  width: @checkboxSize;
  height: @checkboxSize;
  cursor: pointer;
  flex: none;

  @supports (appearance: none) {
    appearance: none;
    border: 1px solid @color-gray;
    background-color: @color-white;
    border-radius: 3px;
    transition:
      background 0.3s,
      border-color 0.3s;
    outline: none; // TODO use box-shadow to draw focus ring
    @scale: 0.67;

    &::after {
      content: '';
      opacity: 0;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: colorize(data-uri(icon('icon_i_102_check_03')), white) no-repeat center;
      background-size: 100%;
      transform: scale(@scale * 1.25);
      transition:
        transform 0.3s ease,
        opacity 0.2s;
    }

    &:checked {
      background-color: @color-orange;
      border-color: @color-orange;
    }

    &:checked::after {
      opacity: 1;
      transform: scale(@scale);
    }
  }
}

//override froms.less checkbox global styles
input[type='checkbox'].f-checkbox,
input[type='radio'].f-checkbox {
  display: block;
  margin: 0px;

  & + label {
    margin: auto 15px;

    &::before,
    &::after {
      display: none;
    }
  }
}
