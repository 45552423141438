.f-faTools {
  position: absolute;
  top: 0;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    padding: 0;
    margin: 5px 0 25px;
    background: @color-white;
    border: 1px solid currentcolor;
    border-radius: 50%;
    color: @color-gray;
    cursor: pointer;
    font-size: 20px;
    outline: none;
    // overflow: hidden;
    transition:
      all 0.25s ease-out,
      color 0.25s ease-out;
    transition-property: background-color, border-color, color, opacity;
    user-select: none;
    -webkit-user-drag: none;
    position: relative;

    &::after {
      font-size: 12px;
      white-space: nowrap;
      // margin-top: 60px;
      position: absolute;
      content: attr(data-legend);
      z-index: 1;
      top: 41px;
    }

    &:hover {
      color: @color-dark;
    }

    &--added {
      color: @color-dark;
    }
  }

  &__icon {
    .f-faTools__item:hover &--default,
    .f-faTools__item--added &--default,
    .f-faTools__item:not(:hover) &--add,
    .f-faTools__item--added &--add,
    .f-faTools__item:not(.f-faTools__item--added) &--added,
    .f-faTools__item--added:hover &--added,
    .f-faTools__item:not(.f-faTools__item--added) &--remove,
    .f-faTools__item--added:not(:hover) &--remove {
      display: none;
    }
  }
}

.f-search__main {
  .f-faTools__item {
    font-size: 16px;
    height: 36px;
    margin: 5px 5px 25px;
    width: 36px;

    &::after {
      top: 38px;
    }

    &.js-share-open {
      display: none;
    }
  }
}
