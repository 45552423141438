@import (reference) '../../../../styles/_library.less';

:root *:focus:not(:focus-visible) {
  outline: 0;
}

// .focus-visible() {
//   outline: 2px solid rgba(@color-orange, 0.5) !important;
//   outline-offset: -2px !important;
// }

:root * {
  .focus-visible();
}

[data-href],
[data-for],
[data-src] {
  &:hover {
    cursor: pointer;
  }
}
