// ContextualNav ---------------------------------------- //
// .ContextualNav-link {
//   color: @color-dark;
//   font-weight: bold;
//   padding: 11px 12px;
//   line-height: 20px;
//   border-left: 5px solid @color-white;

//   &:hover {
//     border-left: 5px solid @color-dark;
//     background: @color-silver-lighter;
//     color: @color-dark;
//   }
// }

.fontnormal {
  font-weight: normal;
  color: @color-gray;
}

.SidebarPromo-link {
  padding: 12px;
  display: inherit;
  line-height: 23px;
  height: 44px;
  color: @color-dark;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  i {
    margin-right: 10px;
    margin-top: -1px;
  } /*ToDelete*/

  .f-icon {
    margin-right: 10px;
    margin-top: -1px;
  }

  &:hover {
    text-decoration: none;
    background: #efefef;
    color: @color-dark !important; // remove me when smile
  }
}

.SidebarPromo-Title {
  color: @color-dark;
  font-weight: bold;
  padding: 11px 12px;
  line-height: 20px;
  text-transform: uppercase;
  margin-right: 1px;
  border-bottom: 1px solid @color-silver-darker;
}

.SidebarPromo-list {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  border-bottom: 1px solid @color-silver-darker;
  margin-right: 1px;
}

.SidebarPromo-item {
  text-transform: uppercase;
  cursor: pointer;
  margin: 0;
  height: 41px;
}

.SidebarPromo-icon {
  background: url('../../../../Images/Sprites/sidebarPromo-icons.jpg') no-repeat;
  background-position: -1000px;
  display: inline-block;
  text-indent: -9999px;
  width: 100%;
  height: 100%;

  html[lang*='-BE'] & {
    background-image: url('../../../../Images/Sprites/sidebarPromo-icons-NL.png');
  }
}

.SidebarPromoPosition(@posY) {
  background-position: -10px @posY;

  &:hover {
    background-position: -261px @posY;
  }

  &.active {
    background-position: -511px @posY;
  }
}

[class*=' meilleures-offres-venteflash'] {
  .SidebarPromoPosition(-8px);
}

[class*=' offreadhérents'],
[class*=' meilleures-offres-adh'] {
  .SidebarPromoPosition(-57px);
}

[class*=' bons-plans'] {
  .SidebarPromoPosition(-115px);
}

[class*=' offres-remboursement'] {
  .SidebarPromoPosition(-163px);
}

[class*=' packs-micro'] {
  .SidebarPromoPosition(-216px);
}

[class*=' meilleures-offres-new'] {
  .SidebarPromoPosition(-266px);
}

[class*=' meilleures-offres-new-soldes'],
[class*=' soldes-'] {
  .SidebarPromoPosition(-361px);
}

[class*=' offres-occasion'] {
  .SidebarPromoPosition(-412px);
}

[class*=' 168x28_picto_packs_securite'] {
  .SidebarPromoPosition(-456px);
}

[class*=' rentree-picto'] {
  .SidebarPromoPosition(-498px);
}

[class*=' noel-high-tech'] {
  .SidebarPromoPosition(-544px);
}

[class*=' picto-TiragePhoto'],
[class*=' picto-tiragephoto'] {
  .SidebarPromoPosition(-594px);
}
