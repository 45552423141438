@import (reference) '../../../../styles/_library.less';

/*
  /!\ There are overrides for "Configurateur Mac" in :
  \Front.Assets\Nav\Css\Less\common\components\configurator\mini-fa.less

  /!\ File used by Care, need to prevent if modify
  \Front.Assets\components\shared-products\shared-products.less

  FIXME move overrides in dedicated file, import this file shouldn't come with unnecessary styles: layoutLight,
*/

.miniFA {
  @thumbnail-padding-horizontal: 10px;
  @thumbnail-padding-bottom: 18px;
  @padding-x: 24px;
  @padding-y: 16px;
  @image-height: 120px;
  @buy-button-z-index: 1; // (use by backdrop of the buy-button too) if use 0 (or set no z-index) the sup of pricelist is over because it use position: relative

  position: relative;
  display: flex;
  flex-direction: column;
  width: 220px;
  max-width: 220px;
  padding: 25px @thumbnail-padding-horizontal @thumbnail-padding-bottom;
  background: @color-white;
  font-weight: normal;
  text-align: left;
  flex: 1 0 auto;

  &.thumbnail {
    // remove that fix when thumbnail are replaced by this component
    height: auto; // let flex stretch it (align-items is default to stretch)
    border: 0;

    // We need to "hide" buy button background elements with a white backdrop on device that support hover
    @media (hover: hover) {
      &:not(.thumbnail--alwaysvisible)::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 33%, white 100%);
        height: 70px;
        z-index: @buy-button-z-index;
      }

      &:not(.thumbnail--alwaysvisible):not(:hover)::before {
        visibility: hidden;
      }
    }
  }

  &--hasBorder {
    border: 1px solid @color-silver-lighter;
  }

  // FnacShop configurator page
  .layoutLight & {
    pointer-events: none;
  }

  // The form contains all children of miniFA, try to be like "display: contents"
  &__form {
    display: inherit;
    flex-direction: inherit;
    flex: inherit;
    height: 100%;
    width: 100%;
  }

  &__seller {
    white-space: normal;
    font-size: 11px;
    display: flex;
    gap: 3px;
    align-items: center;
    flex-wrap: wrap;

    .tooltipFnacPlus-trigger {
      top: 0;
    }
  }

  &__sellerIcon {
    height: 16px;
    width: 16px;
    color: @color-orange;
    vertical-align: middle;
    &Mp {
      height: 14px;
      width: auto;
      //margin-left: 4px;
    }
  }

  &__topSeller {
    margin-left: 4px;
  }

  &__rating {
    height: 17px;
    line-height: 17px;
    overflow: hidden;

    // FnacShop configurator page
    .layoutLight & {
      display: none;
    }
  }

  &__button.ff-button {
    // used with ff-button--block ff-button too
    // Need increase specificity because .ff-button is imported/declared after that class...
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    padding: 12px 15px;
    border-radius: @base-radius;
    font-size: 13px;
    font-weight: normal;
    line-height: 1;
    z-index: @buy-button-z-index;
    min-width: calc(100% - (2 * @thumbnail-padding-horizontal));
    width: auto;

    // For devices that support fine pointer or hover the button is hidden and show on hover
    // But there is some case (with .thumbnail--alwaysvisible) where we don't wan't that behavior
    @media (hover: hover) {
      .thumbnail:not(.thumbnail--alwaysvisible) & {
        position: absolute;
        margin: 0 @thumbnail-padding-horizontal @thumbnail-padding-bottom;
      }
      .thumbnail:not(.thumbnail--alwaysvisible):not(:hover) & {
        visibility: hidden;
      }
    }

    // FnacShop configurator page
    .layoutLight & {
      display: none;
    }

    & i,
    &Icon {
      margin-right: 5px;
      font-size: 16px;
      vertical-align: baseline;
    }
  }

  &__buttonsRow {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 10px 0 0 0;
  }

  /*
  width: auto;
	min-width: 0;
	position: static;
	margin: 0;
	visibility: visible;
   */

  &__top {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
  }

  // This element doesn't contains anything. It's here only for spacing top and bottom elements when resize mini FA (spacing between element at top and element at bottom, like pricelist and button)
  &__spacer {
    flex: 1 1 auto;
    overflow: hidden;
    //height: 20px;
  }

  // This element is useful for layering button over pricelist
  &__bottom {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    min-height: 50px; // but we don't know the exact height of button (absolute and one or more lines)
    justify-content: flex-end;

    @media (hover: none) {
      min-height: auto;
    }
    .thumbnail--alwaysvisible & {
      min-height: auto;
    }
  }

  &__image {
    width: auto;
    height: @image-height;
    margin: 0 auto 10px;
    align-self: center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  &__energyLabel,
  &__greenLabel {
    position: absolute;
    top: calc(@image-height + @padding-x);
    line-height: 32px;
  }

  &__energyLabel {
    left: @padding-y;
    // .thumbnail-energyLabel {
    //   width: 36px;
    // }
  }

  &__greenLabel {
    right: @padding-y;
  }

  &__link {
    position: relative;
    display: block;
    color: @color-dark;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      @link-size: 135px;

      content: '';
      position: absolute;
      top: -@link-size;
      display: block;
      width: 100%;
      height: @link-size;
    }
  }

  &__title,
  &__subtitle {
    align-self: flex-start;
    display: block;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 1.2;
  }

  &__title {
    height: 32px;
    overflow: hidden;
  }

  &__subtitle {
    height: 15px;
    color: @color-gray;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    // FnacShop configurator page
    .layoutLight & {
      display: none;
    }
  }

  &__infos {
    margin-top: 5px;
    flex: 0 1 auto;
    line-height: 15px;
  }

  &__priceList {
    flex: 0 0 auto;
    display: flex;
    height: 35px;
    justify-content: flex-start;
    overflow: hidden;
  }

  &__priceLabel {
    font-size: 10px;
    color: @color-gray;
    font-weight: 200;
  }

  &__price {
    flex: 0 0 auto;
    padding: 0 13px 0 7px;
    display: flex;
    flex-direction: column;
    color: @color-gray;

    &:first-child {
      padding-left: 0;
    }

    &:nth-child(2) .miniFA__amount {
      font-size: 15px;
    }

    &--isMain {
      color: @color-red;
      .userPriceNumerical {
        color: @color-skyBlue;
      }
    }
    &--mp .price {
      color: @color-marketplace;
    }

    .thumbnail-content--mp &--isMain {
      color: @color-marketplace;
    }

    &--hasBorder {
      border-left: 1px solid @color-silver-darker;
    }
  }

  &__oldPrice {
    color: @color-gray;
    align-self: flex-end;
    margin-bottom: 2px;
    margin-right: 10px;
  }

  &__newPrice,
  &__oldPrice,
  &__amount {
    line-height: 1;
  }

  &__amount {
    margin-top: auto;
    font-size: 17px;
    font-weight: bold;
  }

  &__sellerName,
  &__sellerName:hover {
    color: @color-marketplace;
    font-weight: bold;
  }

  &__prid {
    color: @color-silver-darker;
    font-size: 11px;
    margin: 20px 0 0;
    text-align: right;
  }

  &__sponsored {
    color: @color-gray-dark;
    display: block;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    right: 10px;
    z-index: 1;
  }

  &__fulfilled-icon {
    font-size: 32px;
    height: auto;
    margin-top: -3px;
  }

  /* FIXME: the price helper displays a <sup> tag for the decimals, but shouldn't ... */
  /*
    L'affichage des prix sur le site respecte les règles suivantes :
     - Prix principal : € et décimal en exposant calés sur le haut de l'unité
     - Prix barré, prix secondaires, plus d'offres et prix affichés en petite taille : € et décimal affichés de la même taille que l'unité et sans exposant après l'unité
     - Aucun prix avec virgule ne doit apparaître sur la France...
  */
  .thumbnail-moreOfferPrice,
  .thumbnail-numericalFormat {
    sup {
      font-size: 1em;
      top: auto;
    }
  }
}
