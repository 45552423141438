.ad_attribution {
  color: @color-dark;
  text-transform: uppercase;
  font-size: 27px;
  font-weight: 100;
  text-decoration: none !important;

  & .redText {
    color: @color-dark;
  }
}

.blkSense {
  display: block;
  margin-top: 5px;
  float: left;
  width: 32%;
  background-color: @color-white;
  padding: 30px;
  font-size: 13px;
  height: 163px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 991px) {
    width: 49%;
  }
}

.tSense {
  color: @color-dark;

  &.ad_title {
    font-weight: 700;
  }
}

.ad_text_large {
  font-weight: normal;
}

.lSense {
  color: @color-orange;
  text-decoration: none;
}
