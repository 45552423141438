// Do not use utility classes, use mixins instead
.isHidden {
  display: none !important;
}

.table {
  display: table;
}

.table-cell {
  display: table-cell;
}

.nowrap {
  white-space: nowrap;
}
