@import (reference) '../../styles/_library.less';
// ----------------------------------------
// The product count down
// ----------------------------------------

// countdown
.f-countdown {
  & when (@ismobile) {
    text-align: center;
  }
  color: @color-red;
  font-size: 11px;
  margin: 0 0 2px;
  .f-productPageMain &,
  .f-faPriceBox & {
    color: @color-gray-darker;
    font-size: 1.3rem;
    text-align: left;
  }
}

// properties values equal thumbnail-btnCart values
.f-countdown-position {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: @color-white;
  padding-top: 5px;
  font-size: 12px;
  .Article & {
    padding: 0 0 10px;
    position: static;
  }
  .stock {
    text-align: center;
    display: none;
    color: @color-forestGreen;
  }
}

// countdown bar
.f-countdown-progress {
  background: @color-silver-lighter;
  border-radius: 5px;
  height: 10px;
  font-weight: normal;
  position: relative;
  overflow: hidden;

  &Bar {
    background: @color-red;
    border-radius: 5px;
    height: 10px;
    left: 0;
    position: absolute;
  }

  &--small {
    height: 5px;

    .f-countdown-progressBar {
      height: 5px;
    }
  }
}
