@import '../../../../styles/_library.less';

// pagination ----------------------------------------------
// https://fnacdarty.invisionapp.com/dsm/fnac-darty/fnac-design-system/asset/components/5e4668368728f6140e9f6d8c
// See also Src/Front.Assets/styles-nav/css/mobile/search/paginate.less
.paginate {
  background: @color-silver-lighter;
  display: block;
  list-style: none;
  margin: 0;
  padding: 10px;

  &--center {
    text-align: center;
  }

  &-item {
    display: inline-block;
  }

  &-item-number {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 7px 11px;

    &--active,
    &.active {
      background: @color-white;
      border: 1px solid @color-orange;
      border-radius: @base-radius;
      color: @color-orange;
      display: block;
      line-height: 1;
      cursor: default;
      pointer-events: none;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
