@import (reference) '../../styles/_library.less';

.f-kamino-video {
  width: 100%;
  height: auto;
  z-index: 2;

  &-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;

    &:hover {
      .f-kamino-video-controls {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &-canvas {
    width: auto;
    height: 100%;
    outline: 0;
    border-radius: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    filter: blur(3px);
    z-index: 1;
    transform: translate(-50%, -50%) scale(1.795);
  }

  &-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition:
      visibility 0.3s linear,
      opacity 0.3s linear;
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    height: 35%;
    max-height: 100px;

    & when (@ismobile) {
      visibility: visible;
      opacity: 1;
    }

    &::before {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABkCAYAAABHLFpgAAAAAXNSR0IArs4c6QAAAMNJREFUKFNNx9FGAwAAQNFVSstKaVNZKpWysk2zUinbbFkqiSQmiUwkGZMxSUwiSSSRJJLIPnLOW/fhcAOB/7WZdnSgE13oRhA9CKEXfejHAAYRRgRDGMYIohjFGMYxgUlMYRozmEUMc5hHHAkksYAU0ljEEpaxglWsYR0ZZJFDHgVsoIhNbGEbO9jFHvZxgEOUcIRjnOAUZZzhHBe4RAVVXKGGOq5xg1s0cId7POART3jGC17xhnd84BNf+MYPfvGHZgsoCRzvYCJ6NwAAAABJRU5ErkJggg==)
        0 0 repeat;
      content: '';
      width: 100%;
      height: 100%;
    }
  }

  &-sound-icon {
    position: absolute;
    color: white;
    //left position by default
    bottom: 6px;
    left: 6px;

    &[data-pos='right'] {
      right: 6px;
      left: unset;
    }

    &[data-pos='none'] {
      display: none;
    }
  }

  &-progress {
    display: flex;
    width: 100%;
    height: 5px;

    &--filled {
      background-color: @color-orange;
      flex-basis: 0%;
      transition: flex-basis 0.3s linear;
    }
  }
}

.f-kamino-video-notifier {
  width: 100%;
  height: auto;
  margin: 6px;
  display: flex;

  &-wrapper {
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 5%;
    min-width: 30px;
    max-width: 60px;
    aspect-ratio: 1 / 1;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    animation: kmino-fadeout 0.7s linear 1 normal forwards;
    pointer-events: none;
    z-index: 3;

    &[data-hidden='true'] {
      display: none;
    }
  }
}

@keyframes kmino-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
