// MODIFIERS
// f-tooltip--dark : Affiche le tooltip en noir avec du texte blanc, par défaut il est affiché en blanc avec du texte noir
// f-tooltip--top-vendeur : Gère l'affichage de la tooltip Top Vendeur
// f-tooltip--full-width : Passe le contenu de la tooltip en 100%
// f-tooltip--delivery-info : Gère l'affichage de la tooltip Deliveru Info
// f-tooltip--configurator : Style du configurateur

// DATA PARAMETERS
// data-flow="top|bottom" : Affiche le tooltip en dessous de l'élément, par défaut il est affiché au dessus
// data-arrowposition="left|right" : Affiche la flèche du tooltip à gauche ou à droite de l'élément, par défaut elle est affichée au centre
// data-side="left|right" :  Affiche la flèche du tooltip du côté gauche ou du côté droite de l'élément

.f-tooltip {
  // VARS
  --arrow-height: 10px;
  --arrow-width: 10px;
  --arrow-gap: 3px;
  --icon-height: 16px;
  --icon-width: 16px;

  background: transparent;
  color: inherit;
  cursor: pointer;
  font-size: 13px;
  position: relative;
  text-align: var(--text-align, center);
  width: fit-content;
  margin-left: var(--margin-left, 0);

  &__container {
    background: var(--background-container, @color-white);
    color: var(--text-color-container, @color-black);
    border-radius: 4px;
    border: var(--border-container, unset);
    display: block;
    opacity: 0;
    padding: 8px;
    pointer-events: none;
    position: absolute;
    transition: opacity 0.25s ease-out;
    font-weight: normal;
    text-align: var(--text-align, center);

    top: var(--top-container-position, unset);
    bottom: var(--bottom-container-position, unset);
    left: var(--left-container-position, unset);
    right: var(--right-container-position, unset);

    /* Shadows/Shadow #1 */
    box-shadow:
      0px 2px 4px 0px rgba(0, 0, 0, 0.05),
      0px 5px 8px 0px rgba(0, 0, 0, 0.05),
      0px 0px 12px 0px rgba(0, 0, 0, 0.05);
    height: fit-content;
    width: var(--width-container, 120px);
    min-width: var(--with-container, 120px);
    z-index: calc(infinity); // Z-index killer

    &::before {
      content: ' ';
      display: block;
      height: var(--arrow-height, 10px);
      transform: var(--transform-container-pseudo-element, rotate(0));
      position: absolute;
      width: 0;
      bottom: var(--bottom-container-before, -10px);
      top: var(--top-container-pseudo-element, unset);
      left: var(--left-container-pseudo-element, calc(50% - 10px));
      right: var(--right-container-pseudo-element, unset);
      border: var(--border-container-pseudo-element, unset);
      border-style: solid;

      border-width: 10px 10px 0 10px;
      border-color: var(--arrow-border-color, @color-silver-darker transparent transparent transparent);
    }

    &::after {
      content: ' ';
      position: absolute;
      bottom: var(--bottom-container-after, calc(var(--arrow-height) * -1));
      top: var(--top-container-pseudo-element, unset);
      left: var(--left-container-pseudo-element, calc(50% - 10px));
      right: var(--right-container-pseudo-element, calc(50% - 10px));
      height: 0;
      width: 0;
      border-left: solid transparent var(--arrow-height);
      border-right: solid transparent var(--arrow-height);
      border-top: var(--border-top-container-after, solid @color-white var(--arrow-height));
      transform: var(--transform-container-pseudo-element, rotate(0));
    }
  }

  .f-icon {
    color: var(--icon-color, inherit);
    width: var(--icon-width, 16px);
    height: var(--icon-height, 16px);
  }

  &:hover &__container,
  &:focus-visible &__container {
    opacity: 1;
    pointer-events: auto;
    bottom: var(--bottom-container-position-hover, unset);
    top: var(--top-container-position-hover, unset);
    left: var(--left-container-position-hover, unset);
    right: var(--right-container-position-hover, unset);
  }

  &[data-flow='bottom'] {
    --width-container: fit-content;

    --top-container-position: calc(100% + var(--arrow-height) + var(--arrow-gap));
    --left-container-position: calc(-1 * ((var(--width-container) / 2) - (var(--icon-width) / 2)));

    --top-container-position-hover: calc(100% + var(--arrow-height) + var(--arrow-gap));
    --bottom-container-before: unset;
    --left-container-position-hover: calc(-1 * ((var(--width-container) / 2) - (var(--icon-width) / 2)));

    --top-container-pseudo-element: -10px;
    --transform-container-pseudo-element: rotate(180deg);
  }

  &[data-flow='top'] {
    --width-container: fit-content;

    --bottom-container-position: calc(100% + var(--arrow-height) + var(--arrow-gap));
    --top-container-position: unset;
    --left-container-position: calc(-1 * ((var(--width-container) / 2) - (var(--icon-width) / 2)));

    --bottom-container-position-hover: calc(100% + var(--arrow-height) + var(--arrow-gap));
    --top-container-position-hover: unset;
    --left-container-position-hover: calc(-1 * ((var(--width-container) / 2) - (var(--icon-width) / 2)));
  }

  &[data-arrowposition='right'],
  &[data-flow='bottom'][data-arrowposition='right'] {
    --width-container: fit-content;

    --bottom-container-position: unset;
    --top-container-position: calc(100% + var(--arrow-height) + var(--arrow-gap));
    --left-container-position: calc(var(--arrow-width) + var(--arrow-width) + 10px + var(--width-container) * -1);

    --bottom-container-position-hover: unset;
    --top-container-position-hover: calc(100% + var(--arrow-height) + var(--arrow-gap));
    --left-container-position-hover: calc(var(--arrow-width) + var(--arrow-width) + 10px + var(--width-container) * -1);

    --left-container-pseudo-element: auto;
    --right-container-pseudo-element: 10px;
  }

  &[data-flow='top'][data-arrowposition='right'] {
    --width-container: fit-content;

    --bottom-container-position: calc(100% + var(--arrow-height) + var(--arrow-gap));
    --top-container-position: unset;
    --left-container-position: calc(var(--arrow-width) + var(--arrow-width) + 10px + var(--width-container) * -1);

    --bottom-container-position-hover: calc(100% + var(--arrow-height) + var(--arrow-gap));
    --top-container-position-hover: unset;
    --left-container-position-hover: calc(var(--arrow-width) + var(--arrow-width) + 10px + var(--width-container) * -1);

    --left-container-pseudo-element: auto;
    --right-container-pseudo-element: 10px;
  }

  &[data-arrowposition='left'],
  &[data-flow='bottom'][data-arrowposition='left'] {
    --left-container-pseudo-element: 10px;
    --right-container-pseudo-element: auto;

    --left-container-position: calc(-1 * (var(--arrow-gap) + var(--arrow-width)));

    --left-container-position-hover: calc(-1 * (var(--arrow-gap) + var(--arrow-width)));
  }

  &[data-flow='top'][data-arrowposition='left'] {
    --left-container-pseudo-element: 10px;
    --right-container-pseudo-element: auto;
  }

  &[data-side='right'] {
    --bottom-container-position: unset;
    --top-container-position: calc(-100% - (100% - var(--arrow-height)));
    --left-container-position: calc(100% + 13px);
    --right-container-position: unset;

    --bottom-container-position-hover: unset;
    --top-container-position-hover: calc(-100% - (100% - var(--arrow-height)));
    --left-container-position-hover: calc(100% + 13px);
    --right-container-position-hover: unset;

    // ::after / ::before
    --left-container-pseudo-element: -15px;
    --right-container-pseudo-element: auto;
    --top-container-pseudo-element: calc(50% - 5px);
    --transform-container-pseudo-element: rotate(90deg);
  }

  &[data-side='left'] {
    --width-container: fit-content;

    --top-container-position: calc(-100% - (100% - var(--arrow-height)));
    --bottom-container-position: unset;
    --right-container-position: unset;
    --left-container-position: calc((var(--width-container) + 19px) * -1);

    --top-container-position-hover: calc(-100% - (100% - var(--arrow-height)));
    --bottom-container-position-hover: unset;
    --right-container-position-hover: unset;
    --left-container-position-hover: calc((var(--width-container) + 19px) * -1);

    // ::after / ::before
    --left-container-pseudo-element: auto;
    --right-container-pseudo-element: -15px;
    --top-container-pseudo-element: calc(50% - 5px);
    --transform-container-pseudo-element: rotate(-90deg);
  }

  // MODIFIERS
  &--dark {
    --background-container: @color-black;
    --text-color-container: @color-white;
    --border-container-pseudo-element: 0;
    --border-top-container-after: solid @color-black var(--arrow-height);
  }

  // Top Vendeur
  &--top-vendeur {
    --icon-color: @color-orange;
    // --transform-hover: translate(calc(50% - 85px), 10px);
    --text-align: left;
    --width-container: 170px !important;
    --border-container: 0;
    --left-container: calc(50% - (var(--width-container) / 2));

    .f-icon--topSeller {
      font-size: 20px;
      flex: 0 0 20px;

      & when (@ismobile) {
        font-size: 16px;
        flex: 0 0 16px;
      }
    }

    &-small {
      .f-icon--topSeller {
        font-size: 20px;
        flex: 0 0 20px;

        & when (@ismobile) {
          font-size: 16px;
          flex: 0 0 16px;
        }
      }
    }
  }

  // Full Width
  &--full-width {
    width: 100%;
  }

  // Delivery Info
  &--delivery-info {
    --width-container: 200px !important;
    --margin-left: 3px;
    --icon-color: @color-gray;
  }

  // Configurateur
  &--configurator {
    --with-container: 200px;
    --text-align: left;
    --icon-width: 22px;
    --icon-height: 22px;
  }
}
