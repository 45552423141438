@import (reference) '../../../../../styles/_library.less';

.seoLinksStrate {
  position: relative;
  width: 100%;

  &__grid {
    column-width: (@screen-sm-min / 3);
    display: block; // to override flex
    font-size: 13px;

    @media (min-width: @screen-md-min) {
      column-width: 240px;
    }

    &--thin {
      @hpadding: 30px;
      border: solid @color-silver-darker;
      border-width: 1px 0;
      column-width: (@screen-sm-min / 3 - @hpadding * 2);
      padding: 20px @hpadding;
    }
  }

  &__list {
    list-style: none;
    margin: 0 0 1rem;
    padding: 0;

    &--last-child {
      margin: 0;
    }
  }

  &__title {
    color: @color-dark;
    font-weight: bold;
    text-transform: uppercase;

    dt& {
      // don't break between definition title and description
      break-after: avoid-column; // W3C
      page-break-after: avoid; // FF
    }
  }

  &__title,
  &__link {
    display: block;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__link--title {
    color: @color-dark;
  }

  &.expander {
    max-height: 310px;
  }
}
