.Header__basket {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px); // 40px height of CloseBtn

  &-wrapper,
  &--empty {
    .customScrollbars(auto, auto);
  }

  &-list {
    margin: 0;
    padding: 10px;
  }
}

//
// Product ITEM
.LayerBasket__item {
  .shadow-A();
  border-top: 2px solid @color-silver-lighter;
  border-radius: 3px;
  position: relative;
  font-size: 0; // inline-block HACK
  list-style: none;
  margin: 5px 0;

  &--child {
    background-color: @color-grey-light;
    top: -5px;
    border-left: 5px solid @color-green-lighter;
    border-radius: 0 0 3px 3px;
  }

  // &:has(+ .LayerBasket__item--child) {
  //   border-radius: 3px 3px 0 0;
  // }

  // overload Front.Assets/styles-nav/css/common/components/infoTooltip.less
  &-info {
    justify-content: flex-end;
    &-tooltip {
      left: -95px;
      &::after {
        left: 95%;
      }
    }
  }
}

// Delete button
.LayerBasket__item-delete {
  background: 0;
  border: 0;
  // display: none;
  padding: 4px;
  position: absolute;
  right: 0;
  top: 0;

  &::before {
    content: '';
    background: colorize(data-uri(icon('close')), #757575) center / contain no-repeat;
    display: block;
    height: 16px;
    width: 16px;
  }
}

// Price block
.LayerBasket__item-price-wrapper {
  padding: 0 8px 8px;
  font-size: 0;
  text-align: right;
}

.LayerBasket__item-price {
  color: @color-dark;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  text-align: right;

  &--book5pc {
    color: @color-green-dark;
  }

  &--numerical {
    color: @color-skyBlue;
  }

  &--sup,
  sup {
    font-size: 13px;
  }

  &--storePrice {
    border-top: 1px solid @color-silver-darker;
    float: right;
    margin-top: 3px;
    padding-top: 3px;
    position: static;
    color: @color-green-dark;
    font-size: 11px;
    font-weight: bold;
    line-height: 11px;
    text-align: right;
    //TODO: Remove this
    display: none;
  }
}

.LayerBasket__item-oldprice {
  display: inline-block;
  padding-right: 5px;

  color: @color-gray;
  font-size: 12px;
  text-align: right;
  text-decoration: line-through;
}

// Product details
.LayerBasket__item-wrapper {
  padding: 8px 8px 3px;

  font-size: 0;
}

.LayerBasket__item-img-wrapper {
  display: inline-block;
  padding: 0 8px 0 0;
  width: 56px;
  vertical-align: middle;
}

.LayerBasket__item-img {
  display: block;
  max-height: 48px;
  max-width: 100%;
}

.LayerBasket__item-title {
  display: inline-block;
  width: 230px;

  font-size: 13px;
  vertical-align: middle;
}

.LayerBasket__item-link,
.non-legacy .LayerBasket__item-link {
  color: @color-dark;

  &:hover {
    text-decoration: none;
  }
}

// TO REFACTOR
.LayerBasket__buttons {
  display: none;
}
// END TO REFACTOR

.LayerBasket__total {
  background: @color-white;
  padding: 10px;
  box-shadow: 0 -10px 10px -4px rgba(@color-black, 0.05);

  &-progress-text {
    text-align: center;
    font-size: 13px;

    &--completed::before {
      background-image: colorize(data-uri(icon('check-circle')), @color-forestGreen);
      content: '';
      width: 17px;
      height: 17px;
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
    }
  }

  &-progress-container {
    margin-bottom: 15px;
  }

  &-progress-tooltip-icon {
    background-image: colorize(data-uri(icon('help')), @color-black);
    width: 13px;
    display: inline-block;
    height: 13px;
    transform: translateY(2px);
  }
}

.LayerBasketDarcosTooltip {
  z-index: 99999;
}

.LayerBasketDarcosTooltip.drop-theme-arrows .drop-content {
  max-width: 281px;
  background: @color-dark;
  color: @color-white;
  border-color: @color-dark;

  &::before {
    top: 100%;
    border-bottom-color: @color-dark;
    right: 50%;
    border-top: 0 transparent;
    transform: rotate(180deg);
  }
}

.LayerBasket__total-discounts {
  margin-bottom: 10px;
  color: @color-red;
  font-size: 13px;
  text-align: right;
}

.LayerBasket__total-discounts-item {
  margin: 0;
}

.LayerBasket__total-bold {
  font-weight: bold;
}

.LayerBasket__total-price-wrapper {
  margin-bottom: 15px;
  font-size: 0;
}

.LayerBasket__total-text {
  display: inline-block;
  width: 60%;
  text-align: left;
}

.LayerBasket__total-label {
  display: inline-block;
  padding-right: 5px;
  color: @color-black;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  vertical-align: middle;
}

.LayerBasket__total-withoutshippingcost {
  display: inline-block;
  color: @color-gray;
  font-size: 11px;
  line-height: 18px;
  vertical-align: middle;
}

.LayerBasket__total-price {
  display: inline-block;
  width: 40%;
  color: @color-red;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
  vertical-align: middle;
}

.LayerBasket__total-priceSup {
  font-size: 10px;
  vertical-align: super;
}

.LayerBasket__total-button {
  &.btn {
    // border-radius: @base-radius;
    background: @color-orange;
    border-color: @color-orange;
    color: @color-dark;
    // line-height: 25px;
    // padding: 6px 10px;
    // font-size: 13px;
    // text-align: center;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // transform: translateZ(0);
    // transition: 0.25s ease-out;
    // transition-property: background-color, border-color, color, opacity;

    // &:active {
    //   box-shadow: none;
    // }

    &:hover,
    &:focus {
      background: @color-orange-hover;
      color: @color-dark;
    }
  }

  display: block;
  // width: 100%;
  .focus-visible-dark();

  &--1click {
    &.btn {
      border-color: @color-dark;
      background: @color-white;
      margin-top: 16px;
      color: @color-dark;
      // transition: all 0.25s;
      .focus-visible();

      &:hover,
      &:focus {
        background-color: @color-dark;
        color: @color-white;
      }
    }
  }
}

.Header__basket-empty {
  background: @color-white colorize(data-uri(icon('shopping-bag')), @color-silver-darker) center 50px / auto 72px
    no-repeat;
  min-height: 280px;
  padding: 0;
  position: relative;
  width: 100%;
}

.Header__basket-empty-text {
  display: block;
  margin: 0 auto;
  position: relative;
  top: 190px;
  width: 180px;
  color: @color-silver-darker;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}

.Header__basket-inspiration {
  background-color: @color-white;
  text-align: center;
}

.Header__basket-inspiration-title {
  margin-bottom: 15px;
  font-size: 21px;
}

.Header__basket-inspiration-button {
  background: @color-white;
  border: 1px solid @color-dark;
  // border-radius: 3px;
  // display: inline-block;
  // cursor: pointer;
  // transition: all 0.2s linear;
  // overflow: hidden;
  // padding: 6px 10px;
  width: 100%;
  color: @color-dark;
  // font-size: 13px;
  // line-height: 30px;
  // text-align: center;
  // text-overflow: ellipsis;
  // white-space: nowrap;

  &:hover,
  &:focus {
    background: @color-dark;
    color: @color-white;
  }
}

.Header__basket-inspiration-button--bottom {
  margin-bottom: 15px;
}

.BasketList-quantityContainer {
  position: absolute;
  bottom: 0;
  padding: 0 5px;
  display: flex;
}

.BasketList-decrease,
.BasketList-increase,
.BasketList-quantity {
  transition: color 0.3s ease;
  border: 0;
  background: none;
  display: inline-block;
  padding: 0;
  text-align: center;
  width: 20px;
  height: 26px;
  font-size: 20px;
  color: @color-orange;
  line-height: 26px;

  &:hover {
    color: @color-dark;
  }
}

.BasketList-quantity {
  color: @color-dark;
  font-size: 15px;
  // height: 26px;
  // line-height: 26px;
  width: 30px;
}

// .BasketList-decrease,
// .BasketList-increase {
//   height: 26px;
//   font-size: 20px;
//   color: @color-orange;
//   line-height: 26px;
// }

// .BasketList-increase {
//   line-height: 26px;
// }

// .BasketList-decrease {
//   line-height: 26px;
// }
